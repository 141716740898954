import jwtDecode from "jwt-decode";

export function isAuthenticated() {
  const token = window.localStorage.getItem("API_TOKEN");
  if (token == null) {
    return false;
  }
  try {
    const decoded: any = jwtDecode(token);
    if (!decoded || decoded.exp == null) {
      return false;
    }
    return Date.now() > decoded.exp * 1000 ? false : true;
  } catch (e) {
    return false;
  }
}

export function getToken() {
  return window.localStorage.getItem("API_TOKEN");
}

export function setToken(token: string) {
  window.localStorage.setItem("API_TOKEN", token);
}

export function removeToken() {
  return window.localStorage.removeItem("API_TOKEN");
}
